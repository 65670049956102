import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MainModule } from './modules/main/main.module';
import { SharedModule } from './core/components/shared/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingInterceptorService } from './core/services/interceptors/loading-interceptor.service';
import { ChartsModule } from 'ng2-charts';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    NgxSpinnerModule,
    NgxPermissionsModule.forRoot(),
    ChartsModule,
    TourMatMenuModule.forRoot()
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptorService,
    multi:true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
