import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class GlobaldateService {
  private globaldate: Date = new Date();

  convertFormat () {
    return (this.globaldate.toISOString().split('T')[0].split('-')[1] + '-' + this.globaldate.toISOString().split('T')[0].split('-')[2] + '-' + this.globaldate.toISOString().split('T')[0].split('-')[0]);
  }

  subject = new Subject<Date>();
  constructor() { 
    this.subject.subscribe({
      next: (date) => {
        this.globaldate = date;
      }
    });
  }
  
  sendMessage(date: Date) {
      this.subject.next(date);
  }

  clearMessages() {
      this.subject.next();
  }

  onMessage(): Observable<Date> {
      return this.subject.asObservable();
  }

  getDate(): Date{
    return this.globaldate;
  }
}
