import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './core/services/interceptor.service';
import {CallbackComponent} from './core/callback/callback.component'
import { AuthGuardService } from './core/services/auth-guard.service';
import { AppResolver } from './app.resolver';

const routes: Routes = [
  {
    path:'',
    loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule),
    canActivate:[AuthGuardService],
    resolve: {
      clients: AppResolver
    }
  },
  {path:'user',loadChildren:() =>import('./modules/user/user.module').then(m=>m.UserModule)},
  {path:'callback', component:CallbackComponent},
  {path: '**', pathMatch:'full', redirectTo:''},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    AppResolver
  ]
})
export class AppRoutingModule { }
