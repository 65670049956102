import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/core/services/http.service';
import { defaultTheme } from './core/models/Theme/DefaultTheme';
import { AuthService } from './core/services/auth.service';
import { GlobalClientService } from './core/services/global-client.service';
import { GlobalTenantService } from './core/services/global-tenant.service';
import { ThemeService } from './core/services/theme.service';

@Injectable({
  providedIn: 'root'
})
export class AppResolver implements Resolve<any> {
  constructor(private http: HttpService,private globalClientService: GlobalClientService
    ,private authService:AuthService, private globalTenantService: GlobalTenantService
    , private themeService: ThemeService
    ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<Observable<any> | Promise<any> | any> {
    await this.authService.getUser$().toPromise();
    let clients = await this.http.GetClientsByRole().toPromise();
    this.globalClientService.setClients(clients);
    this.themeService.setTheme(defaultTheme);
    return;
  }
    
}
