import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoadingService } from '../loading-service.service';

@Injectable({
  providedIn: 'root'
})
export class LoadingInterceptorService implements HttpInterceptor {

  constructor(private _loading:LoadingService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var ignoreLoadingBar = request.headers.get('ignoreLoadingBar') == 'true';
    if(!ignoreLoadingBar){
      this._loading.setLoading(true, request.url);
    }
    return next.handle(request)
      .pipe(catchError((err) => {
        if(!ignoreLoadingBar)
        this._loading.setLoading(false, request.url);
        return err;
      }))
      .pipe(map<any,any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse && !ignoreLoadingBar)
        this._loading.setLoading(false, request.url);
        return evt;
      }));
  }
}
