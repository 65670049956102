export const defaultTheme = new Map([
    ["--MAIN-COLOR", "#26A59A"],
    ["--LIGHT-MAIN-COLOR","#26A59A80"],
    ["--WHITE-COLOR", "#FBFDF9"],
    ["--PURE-BLACK", "#000000"],
    ["--DARK-BLUE", "#41495F"],
    ["--PURE-WHITE", "#FFFFFF"],
    ["--GRAY", "#878C9A"],
    ["--LIGHT-GRAY", "#C4C4C4"],
    ["--DARK-WHITE", "#DCDCDC"],
    ["--LIGHT-BLACK", "#000B28"],
    ["--SCROLLBAR-BG-COLOR", "#e2e3e2"],
    ["--COLOR3", "#EFEFEF"],
    ["--COLOR4", "#575E72"],
    ["--COLOR5", "#ECEDEB"],
    ["--COLOR6", "#FAFAFA"],
    ["--COLOR7", "#9CA0AB"],
    ["--COLOR8", "#666D7E"],
    ["--COLOR9", "#F2F3F033"],
    ["--CARD-SHADOW-COLOR", "#41495F4D"],
    ["--COLOR10", "#00000040"],
    ["--COLOR11", "#92EAB84D"],
    ["--COLOR12", "rgba(107, 114, 131, 0.5)"],
    ["--COLOR13", "rgba(156,160,171,0.25)"],
    ["--COLOR14", "rgba(65, 73, 95, 0.3)"],
    ["--COLOR15", "rgba(251, 253, 249, 0.464422)"],
    ["--NEGATIVE-COLOR", "#D5A419"],
    ["--CLIENT-NAV-BG", "rgba(196, 196, 196, 0.9)"],
    ["--FONT-FAMILY", "'Spartan'"],
])
    