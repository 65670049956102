import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Activity } from '../models/Activity';
import { Client } from '../models/Client';
import { EmissionFactor } from '../models/EmissionFactor';
import { EmissionFactorValue } from '../models/EmissionFactorValue';
import { Group } from '../models/Group';
import { Questionary } from '../models/Questionary/Questionary';
import { QuestionaryAnswer } from '../models/Questionary/QuestionaryAnswer';
import { Record } from '../models/Record';
import { Scope } from '../models/Scope';
import { File } from '../models/File';
import { Subscope } from '../models/Subscope';
import { Unit } from '../models/Unit';
import { Variable } from '../models/Variable';
import { YearTotalEmissions } from '../models/Indicators/YearTotalEmissions';
import { GlobalClientService } from './global-client.service';
import { GlobalTenantService } from './global-tenant.service';
import { GlobaldateService } from './globaldate.service';
import { RolesService } from './roles.service';
import { TotalEmissions } from '../models/Indicators/TotalEmissions';
import { RecordVariable } from '../models/RecordVariable';
import { AverageEmissions } from '../models/Indicators/AverageEmissions';

@Injectable({
  providedIn: 'root'
})

export class HttpService {
  
  baseUrl='https://carbon-footprint-api.azurewebsites.net/'; //prod
  // baseUrl='https://carbon-footprint-api-staging.azurewebsites.net/' //staging
  // baseUrl='https://carbon-footprint-api-develop.azurewebsites.net/'; //dev
  // baseUrl='https://localhost:44320/';//Local
  // baseUrl='https://localhost:5001/';//Local

  //baseDocumentUrl : string = 'https://carbonfootprintstorage.blob.core.windows.net/'; //AzureBlobStorage

  constructor(private http:HttpClient,private globalClientService:GlobalClientService,
    private rolesService:RolesService, private globalDateService:GlobaldateService,
    private globalTenantService:GlobalTenantService,) { }

  errorHandl(error:any) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  Get(path: string, ignoreLoadingBar?: boolean, params?: HttpParams, responseType?:any): Observable<Object> {
    if (ignoreLoadingBar) {
      return this.http.get<Object>(this.baseUrl + path, {
        headers: { ignoreLoadingBar: 'true'},
        params: params
      })
        .pipe(
          catchError(this.errorHandl)
        );
    } else {
      return this.http.get<Object>(this.baseUrl + path, {responseType: responseType, params: params})
        .pipe(
          catchError(this.errorHandl)
        );
    }
  }

  Post(path: string, obj: Object, ignoreLoadingBar?: boolean): Observable<Object> {
    if (ignoreLoadingBar) {
      return this.http.post<Object>(this.baseUrl + path, {
        headers: { ignoreLoadingBar: 'true' }
      }, obj)
        .pipe(
          catchError(this.errorHandl)
          );
    } else {
      return this.http.post<Object>(this.baseUrl + path, obj)
        .pipe(
          catchError(this.errorHandl)
          );
    }
  }

  Delete(path: string, ignoreLoadingBar?: boolean, params?: HttpParams): Observable<Object> {
    if (ignoreLoadingBar) {
      return this.http.delete(this.baseUrl + path, {
        headers: { ignoreLoadingBar: 'true' },
        params: params
      })
        .pipe(
          catchError(this.errorHandl)
        );
    } else {
      return this.http.delete(this.baseUrl + path)
        .pipe(
          catchError(this.errorHandl)
        );
    }
  }

  Update(path: string, obj: Object, ignoreLoadingBar?: boolean): Observable<Object> {
    if (ignoreLoadingBar) {
      return this.http.put<Object>(this.baseUrl + path, {
        headers: { ignoreLoadingBar: 'true' }
      }, obj)
        .pipe(
          catchError(this.errorHandl)
        );
    } else {
      return this.http.put<Object>(this.baseUrl + path, obj)
        .pipe(catchError(this.errorHandl));
    }
  }

  GetEnviroment(){
    return <Observable<string>> this.Get('Azure/GetAzureUrl', false, undefined, 'text');
  }

  DeleteRecord(recordId: number){
    return <Observable<Record>> this.Delete('Record/Delete/' + recordId,false);
  }

  UpdateActivity(activity: Activity){
    return <Observable<Activity>> this.Update('Activity/Update/' + activity.id, activity, false);
  }

  UpdateEmissionFactor(FactorWithFiles : any){
    return <Observable<EmissionFactor>> this.Update('EmissionFactor/UpdateFactorAndFiles/' + FactorWithFiles.EmissionFactor.id, FactorWithFiles, false);
  }

  UpdateEmissionFactorValue(factorValueWithFile : any){
    return <Observable<EmissionFactorValue>> this.Update('EmissionFactorValue/UpdateEmissionFactorValueAndFile', factorValueWithFile, false);
  }

  DeleteGroup(groupId: number){
    return <Observable<Group>> this.Delete('Group/Delete/' + groupId,false);
  }

  DeleteActivity(activityId: number){
    return <Observable<Activity>> this.Delete('Activity/Delete/' + activityId,false);
  }

  DeleteEmissionFactor(emissionFactorId: number){
    return <Observable<EmissionFactor>> this.Delete('EmissionFactor/DeleteEmissionFactorAndFiles/' + emissionFactorId,false);
  }

  GetYearTotalEmissions(ignoreLoadingBar: boolean = false){
    return <Observable<YearTotalEmissions>> this.Get(`Report/GetYearTotalEmissions?tenantId=${this.globalTenantService?.tenantId}&date=${this.globalDateService.getDate().toISOString().split('T')[0]}`, ignoreLoadingBar);
  }

  GetClientYearTotalEmissions(ignoreLoadingBar: boolean = false){
    return <Observable<YearTotalEmissions>> this.Get(`Report/GetYearTotalEmissions?tenantId=${0}&date=${this.globalDateService.getDate().toISOString().split('T')[0]}&clientId=${this.globalClientService?.clientId}`, ignoreLoadingBar);
  }

  GetLastYearsEmissions(ignoreLoadingBar: boolean = false){
    return <Observable<TotalEmissions[]>> this.Get(`Report/GetLaterYearsEmissions?tenantId=${this.globalTenantService?.tenantId}&date=${this.globalDateService.getDate().toISOString().split('T')[0]}`, ignoreLoadingBar);
  }

  GetClientLastYearsEmissions(ignoreLoadingBar: boolean = false){
    return <Observable<TotalEmissions[]>> this.Get(`Report/GetLaterYearsEmissions?tenantId=${0}&date=${this.globalDateService.getDate().toISOString().split('T')[0]}&clientId=${this.globalClientService?.clientId}`, ignoreLoadingBar);
  }

  GetTenantAverageEmissions(ignoreLoadingBar: boolean = false){
    return <Observable<AverageEmissions>> this.Get(`Report/GetAverageEmissions?tenantId=${this.globalTenantService?.tenantId}&date=${this.globalDateService.getDate().toISOString().split('T')[0]}`, ignoreLoadingBar);
  }

  GetClientAverageEmissions(ignoreLoadingBar: boolean = false){
    return <Observable<AverageEmissions>> this.Get(`Report/GetAverageEmissions?tenantId=${0}&date=${this.globalDateService.getDate().toISOString().split('T')[0]}&clientId=${this.globalClientService?.clientId}`, ignoreLoadingBar);
  }

  GetScopes(){
    return <Observable<Scope[]>> this.Get('Scope/Get',true);
  }

  GetScopesWithSubScopes(){
    return <Observable<Scope[]>> this.Get('Scope/GetWithSubScope',true);
  }

  GetClients(){
    return <Observable<Client[]>> this.Get('Client/Get',true);
  }

  GetClient(clientId:number){
    return <Observable<Client>> this.Get(`Client/Get/${clientId}`,true);
  }

  GetAllSpecificEmissionFactors(){
    return <Observable<EmissionFactor[]>> this.Get(`EmissionFactor/GetAllSpecificEmissionFactors/?tenantId=${this.globalTenantService?.tenantId}`);
  }

  GetEmissionFactorsWithFilter(filter : any){
    return <Observable<EmissionFactor[]>> this.Get('EmissionFactor/Filter',false,filter);
  }

  GetEmissionFactorHistory(id : any){
    return <Observable<EmissionFactorValue[]>> this.Get('EmissionFactorValue/GetEmissionFactorHistory?EmissionFactorId='+id,false);
  }

  GetSubScopes(){
    return <Observable<Subscope[]>> this.Get('SubScope/Get',true);
  }

  CreateFactor(FactorWithFiles : any) {
    return <Observable<EmissionFactor>> this.Post('EmissionFactor/PostWithFiles',FactorWithFiles);
  }

  DeleteEmissionFactorDocument(factor : EmissionFactor){
    return <Observable<EmissionFactor>> this.Update('EmissionFactor/DeleteEmissionFactorAndFiles', factor);
  }
  
  GetSubScopesGroupsActivities(){
    return  <Observable<Subscope[]>> this.Get(`Subscope/GetSubScopesGroupsActivities?tenantId=${this.globalTenantService?.tenantId}`,true);
  }

  GetConsultorActivitiesList(subScopeId:number = 0,groupId:number = 0,activityName:string = "", ignoreLoadingBar : boolean = false){
    return  <Observable<Subscope[]>> this.Get(`Subscope/ConsultorActivitiesFilter?TenantId=${this.globalTenantService.tenantId}&SubScopeId=${subScopeId}&GroupId=${groupId}&ActivityName=${activityName}`,ignoreLoadingBar);
  }

  GetActivityVariables(activityId: Number, ignoreLoadingBar : boolean = false) {
    return <Observable<Variable[]>> this.Get(`Variable/GetVariablesByActivity?activityId=` + activityId );
  }
  
  GetTenantVariables() {
    return <Observable<Variable[]>> this.Get(`Variable/GetTenantVariables?tenantId=${this.globalTenantService?.tenantId}`);
  }

  SaveRecord(record: any, activityGroupId: Number, file?: File) {
    let recordAndFile = { "record" : record, "file" : file}
    return <Observable<Record>> this.Post('Record/AddRecord?activityGroupId=' + activityGroupId, recordAndFile);
  }

  DeleteEmissionFactorValueDocument(efv: EmissionFactorValue){
    return <Observable<EmissionFactorValue>> this.Update("EmissionFactorValue/DeleteFilePath", efv);
  }

  DeleteRecordDocument(record: Record, ignoreLoadingBar : boolean = false){
    return <Observable<Record>> this.Update('Record/UpdateFilePath', record, ignoreLoadingBar);
  }

  GetGroups(){
    return <Observable<Group[]>> this.Get('Group/Get');
  }

  GetUnits(){
    return <Observable<Unit[]>> this.Get('Unit/Get');
  }

  GetSubscope(subscopeId : number ){
    return <Observable<Subscope>> this.Get('Subscope/Get/' + subscopeId);
  }

  AddGroup(group : Group){
    return <Observable<Group>> this.Post('Group/Add', group);
  }

  SaveActivity(activity : Activity){
    return <Observable<Activity>> this.Post('Activity/Add', activity);
  }

  GetSubscopeWithGroups(subscopeId : number){
    return <Observable<Subscope>> this.Get('Subscope/GetSubScopeWithGroups?SubscopeId=' + subscopeId);
  }

  GetActivityRecords(activityId: number) {
    return <Observable<Record[]>> this.Get(`Record/GetActivityRecords?activityId=${activityId}`);
  }

  GetQuestionary(){
    return <Observable<Questionary>> this.Get(`Questionary/GetBusinessQuestionary/${this.globalTenantService.tenantId}`);
  }
  GetClientsByRole(){
    return <Observable<Client[]>> this.Get(`Client/ClientsByAppRole?clientId=${this.globalClientService.clientId}&appRole=${this.rolesService.getRoles().length > 0 ? this.rolesService.getRoles()[0]: ''}`,true)
  }

  SaveQuestionary(questionaryAnswer : QuestionaryAnswer) {
    questionaryAnswer.createdDate = this.globalDateService.getDate();
    return <Observable<QuestionaryAnswer>> this.Update('QuestionaryAnswer/AddQuestionaryAnswers/' + this.globalDateService.getDate().toISOString().split('T')[0],questionaryAnswer);
  }

  UpdateRecord(record : Record, ignoreLoadingBar : boolean = false){
    return <Observable<Record>> this.Update('Record/Update/'+record.id,record,ignoreLoadingBar);
  }

  GetPeriodValueForActivity(period: string, variablesIds: number[], date: Date, ignoreLoadingBar : boolean = false) {
    return <Observable<RecordVariable[]>> this.Post(`Record/GetPeriodValueForActivity?period=${period}&userDate=${date.toISOString().split('T')[0]}`,variablesIds,ignoreLoadingBar);
  }

  GetQuestionaryAnswers(){
    return <Observable<QuestionaryAnswer>> this.Get('QuestionaryAnswer/GetQuestionaryAnswers/' + this.globalTenantService.tenantId + "/" + this.globalDateService.getDate().toISOString().split('T')[0]);
  }

}
