import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Client } from '../models/Client';
import { Tenant } from '../models/Tenant';

@Injectable({
  providedIn: 'root'
})
export class GlobalClientService {
  clientId:number = 0;
  clients:Client[] = [];
  subject = new Subject<number>();

  setClients( data : Client[]){
    this.clients = data.sort((c1,c2) => c1.name.localeCompare(c2.name));
    this.subject.next(this.clients[0]?.id);
  }

  getClients(){
    return this.clients;
  }

  setClient(clientId : number) {
    this.clientId = clientId || 0;
  }

  getClientTenants():Tenant[]{
    return this.clients.find((client) => client.id == this.clientId)?.tenants || [];
  }
  
  constructor() {
    this.subject.subscribe({
      next: (clientId) => {
        this.setClient(clientId);
      }
    });
  }
}
