import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { BehaviorSubject, combineLatest, from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, shareReplay, tap } from 'rxjs/operators';
import { clientId, domain } from '../../../../auth_config.json';
import { GlobalClientService } from './global-client.service';
import { GlobalTenantService } from './global-tenant.service';
import { RolesService } from './roles.service';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  imageUrl: string = '';
  nameSpace:string = "https://carbon-footprint.azurewebsites.net";
  loginQty:number = 0;
  // Create an observable of Auth0 instance of client
  auth0Client$ = (from(
    createAuth0Client({
      domain: domain,
      client_id: clientId,
      redirect_uri: `${window.location.origin}/callback`,
      audience: 'https://localhost:44320/'
    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError(err => throwError(err))
  );
  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => this.loggedIn = res)
  );
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  loggedIn: boolean = false;

  constructor(private router: Router,private rolesService: RolesService
    ,private globalClientService: GlobalClientService, private globalTenantService : GlobalTenantService
    ,private themeService:ThemeService
    ) {
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.localAuthSetup();
    // Handle redirect from Auth0 login
    this.handleAuthCallback();
  }

  getTokenSilently$(options?:any): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
    );
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?:any): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(async (user:any) => {
        this.userProfileSubject$.next(user);
        this.imageUrl = user?.picture || '';

        let roles = user[`${this.nameSpace}/roles`];
        if(roles){
          this.rolesService.setRoles(roles);
        }
        const userMetadata = user[`${this.nameSpace}/user_metadata`];
        let idClient = userMetadata.IdClient;
        this.globalClientService.subject.next(parseInt(idClient));
        const clientStyles = new Map<string,string>();
        if(userMetadata?.NavBarColor)
          clientStyles.set('--CLIENT-NAV-BG',userMetadata?.NavBarColor);
        this.themeService.setTheme(clientStyles);
      })
    );
  }

  getAccessToken$():Observable<any>{
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently()).pipe(  //getTokenSilently get access_token
        map((token: any) =>{
          const helper = new JwtHelperService();
          token = helper.decodeToken(token); 
          this.rolesService.setPermissions(token.permissions.map((permission:string) => permission.trim()))
          this.loginQty = token[`${this.nameSpace}/loginQty`];
        }
      )))
    );
  }
  private localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          this.getAccessToken$().subscribe(data => {});
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe();
  }

  login(redirectPath: string = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}/callback`,
        appState: { target: redirectPath }
      });
    });
  }

  public handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
    const params = window.location.search;
    if (params.includes('code=') && params.includes('state=')) {
      let targetRoute: string; // Path to redirect to after login processsed
      const authComplete$ = this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        tap(cbRes => {
          // Get and set target redirect route from callback results
          targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
        }),
        concatMap(() => {
          // Redirect callback complete; get user and login status
          return combineLatest([
            this.getUser$(),
            this.isAuthenticated$,
            this.getAccessToken$()
          ]);
        })
      );
      // Subscribe to authentication completion observable
      // Response will be an array of user and login status
      authComplete$.subscribe(([user, loggedIn]) => {
        
        this.loginQty = user[this.nameSpace + '/loginQty'];
        this.router.navigate(['/home'],{state: {isOnBoardingOpen: true }});
        // Redirect to target route after callback processing
      });
    }
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: clientId,
        returnTo: `${window.location.origin}`
      });
    });
  }


  getImage(){
    return this.imageUrl;
  }

}
