import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ThemeService{
  cssProperties:Map<string,string> = new Map();

  constructor(
    @Inject(DOCUMENT) private document:Document
  ){ }
  
  addThemeProperty(name:string,value:string){
    this.cssProperties.set(name,value);
    this.fetchCss();
  }

  //Also can be used for set more than one variable
  setTheme(properties: Map<string,string> = new Map()){
    properties.forEach((value,key)=> {
      this.cssProperties.set(key,value);
    })
    this.fetchCss();
  }

  //overwrite css variables
  private fetchCss(){
    this.cssProperties.forEach((value,key,map)=>{
      this.document.documentElement.style.setProperty(key,value);
    })
  }
}
