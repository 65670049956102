import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout'
import { NgxPermissionsModule } from 'ngx-permissions';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';



@NgModule({
  declarations: [
    ConfirmDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    FlexModule,
  ],
  exports:[
    MaterialModule,
    FormsModule,
    FlexModule,
    NgxPermissionsModule
  ]
})
export class SharedModule { }
