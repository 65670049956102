import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TourService } from 'ngx-ui-tour-md-menu';
import { delay } from 'rxjs/operators';
import { HttpService } from './core/services/http.service';
import { LoadingService } from './core/services/loading-service.service';
import { ThemeService } from './core/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'FPAutomationWeb';

  loading:boolean = false;

  constructor(public http: HttpService,private _loading:LoadingService,private spinner:NgxSpinnerService,
    private tourService:TourService,
    private themeService:ThemeService,
    ) {
      
  }
  ngOnInit(){
    this.spinner.show('sp3');
    this.listenToLoading();
    this.tourService.initialize([
    {
      anchorId: 'home',
      title: 'Página de inicio',
      content: 'Visualice el listado de actividades e ingrese los detalles de cada una.',
    }, {
      anchorId: 'records',
      title: 'Registros',
      content: 'Visualice los detalles de cada actividad e ingrese sus datos.',
    }, {
      anchorId: 'reports',
      title: 'Reportes',
      content: 'Visualice sus emisiones y remociones.',
    }, {
      anchorId: 'info',
      title: 'Información de interés',
      content: 'Conozca los principios y requerimientos que la herramienta busca cumplir',
    }, {
      anchorId: 'questionary',
      title: 'Cuestionario',
      content: 'Comencemos el proceso y rellenemos los datos de su negocio!',
    }
    ],
    {
      enableBackdrop:true,
      nextBtnTitle: 'Sig',
      prevBtnTitle: 'Ant',
      endBtnTitle: 'Fin',

    }); 
  }


  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }
}