import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Roles } from '../models/Enumerators/Roles';
import { GlobaldateService } from './globaldate.service';

const VIEW_ALL_ACTIVITIES = "ViewAll:Activities" 
const CREATE_ACTIVITIES = 'Create:Activities';
@Injectable({
  providedIn: 'root'
})
export class RolesService {
  private roles : string[] = [];
  private permissions : string[] = [];


  constructor(private globalDateService:GlobaldateService, private permissionsService:NgxPermissionsService ) { 
  }

  setRoles(value : string[]){
    this.roles =  value;
  }

  getRoles(){
    return this.roles;
  }

  setPermissions(value: string[]){
    this.permissionsService.loadPermissions(value);
    this.permissions = value;
  }

  getPermissions(){
    return this.permissions;
  }

  canViewSpecificDesign(){
    return this.getRoles().includes(Roles.User) || this.getRoles().includes(Roles.AdvancedUser);
  }

  isUser(){
    return this.getRoles().includes(Roles.User);
  }

  isConsultor(){
    return this.getRoles().includes(Roles.Consultor);
  }

  canCreateActivities(){
    return this.getPermissions().find(permit => permit.trim() === CREATE_ACTIVITIES)
  }
}
