import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Tenant } from '../models/Tenant';
import { GlobalClientService } from './global-client.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalTenantService {

  constructor(private globalClientService:GlobalClientService,) {
    this.subject.subscribe({
      next: (tenantId: number) => {
        this.setTenant(tenantId);
      }
    });
    this.globalClientService.subject.subscribe((clientId)=>{
      this.setTenants(this.globalClientService.getClientTenants());
    })
  }

  tenantId:number = 0;
  tenants:Tenant[] = [];
  subject = new Subject<number>();
  tenantFirstDay:Date = new Date(2000,6,1);//1st of July


  setTenants( data : Tenant[]){
    this.tenants = data.sort((t1,t2) => t1.name.localeCompare(t2.name));
    this.subject.next(this.tenants[0]?.id);
  }

  getTenants(){
    return this.tenants;
  }

  private setTenant(tenantId : number) {
    this.tenantId = tenantId || 0;
  }

  getYearFirstDate(date:Date){
    let firstDay:Date = new Date(date.getFullYear(),this.tenantFirstDay.getMonth(),this.tenantFirstDay.getDate());
    return date >= firstDay ? firstDay : new Date(firstDay.getFullYear() - 1, firstDay.getMonth(), firstDay.getDate());
  }
}
