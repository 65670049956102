<h1 mat-dialog-title>
    {{title}}
</h1>

<div mat-dialog-content>
    <p class="ml-3">{{message}}</p>
</div>

<div mat-dialog-actions fxLayoutAlign="end center">
    <button mat-button (click)="onDelete()">Cancelar</button>
    <button mat-raised-button cdkFocusInitial class="bg-light-green" (click)="onCommand()">Guardar</button>
</div>
